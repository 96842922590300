import React, {Component} from "react";
import PropTypes from "prop-types";
import { OptionCircle, Flex, QuizContainer, Square } from '../ArticleQuiz/styles';
import {FieldTitle, Layout, StyledInput, TitleContainer, CustomMenuItem} from "../CreateEvent/styles";
import {TimeContainer} from "../CreateRecipe/styles";
import {ChoiceContainer, SectionContainer, RadioButtonSurveyConatiner} from "../CreateSurveys/styles";
import { InputFieldContainer, CustomTextAreaField } from '../SocialFeeds/styles';
import RadioButton from "../CustomRadioButton";
// import {surveyOptionTypes} from '../../../mockData.json';
import { ActivityDropdown, ActivityTypeContainer } from '../CreateChallengeForm/styles';
import { NewPlusIcon, closeIcon } from "../../utils/icons";
const OptionTypes = ['Scale', 'MCQ', 'Yes or No', 'Short Description'];
class SurveyQuestions extends Component{
  constructor(props){
    super(props);
    this.state = {
      hasSections: 1,
      prevSectionsData: [
        {
          "title": '',
          "data": [
            {
              "question": '',
              "options": ['']
            }
          ]
        }
      ],
      prevQuestionsData: [
        {
          "question" : '',
          "options": ['']
        }
      ],
      questions: [],
      sections: []
    }
  }

  surveyOptionTypes=[
    {"key": "Scale",
      "questionType":  1,
      "options": ["Strongly Agree", "Agree", "Neutral", "Disagree", "Strongly Disagree"]
    },
    {"key": "MCQ",
      "questionType":  2,
      "options": [""]
    },
    {"key": "Yes or No",
      "questionType":  3,
      "options": ["Yes", "No"]
    },
    {"key": "Short Description",
      "questionType":  4,
      "options": []
    }
  ]

  optionChoice = (index) => 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(index);

  enterQuestion = (e, section, index) => {
    const {sections, questions, updateSurveySections, updateSurveyQuestions} = this.props;
    if (section !== null) {
      let dupSections = [...sections];
      if (dupSections[section].data[index].question.length < e.target.value.length) {
        if (e.target.value.trim() !== '') {
          dupSections[section].data[index].question = e.target.value;
          updateSurveySections(dupSections);
        }
      }
      else {
        dupSections[section].data[index].question = e.target.value;
        updateSurveySections(dupSections);
      }
    } else {
      let dupQuestions = [...questions];
      if (dupQuestions[index].question.length < e.target.value.length) {
        if (e.target.value.trim() !== '') {
          dupQuestions[index].question = e.target.value;
          updateSurveyQuestions(dupQuestions);
        }
      }
      else {
        dupQuestions[index].question = e.target.value;
        updateSurveyQuestions(dupQuestions);
      }
    }
  };

  addChoice = (section, index) => {
    const {sections, questions, updateSurveySections, updateSurveyQuestions} = this.props;
    if (section !== null) {
      let dupSections = [...sections];
      if (dupSections[section].data[index].options.length < 10) {
        dupSections[section].data[index].options.push('');
      }
      updateSurveySections(dupSections);
    } else {
      let dupQuestions = [...questions];
      if (dupQuestions[index].options.length < 10) {
        dupQuestions[index].options.push('');
      }
      updateSurveyQuestions(dupQuestions);
    }
  };
  deleteChoice = (section, question, index) => {
    const {sections, questions, updateSurveySections, updateSurveyQuestions} = this.props;
    if (section !== null) {
      let dupSections = [...sections];
      if (dupSections[section].data[question].options.length > 1) {
        dupSections[section].data[question].options.splice(index, 1);
      }
      updateSurveySections(dupSections);
    } else {
      let dupQuestions = [...questions];
      if (dupQuestions[question].options.length > 1) {
        dupQuestions[question].options.splice(index, 1);
      }
      updateSurveyQuestions(dupQuestions);
    }
  };
  deleteSection = (index) => {
    const {sections, updateSurveySections} = this.props;
    let dupSections = [...sections];
    if(dupSections.length > 1) {
      dupSections.splice(index, 1);
    }
    updateSurveySections(dupSections);
  };
  deleteQuestion = (section, index) => {
    const {sections, questions, updateSurveySections, updateSurveyQuestions} = this.props;
    if (section !== null) {
      let dupSections = [...sections];
      if (dupSections[section].data.length > 1) {
        dupSections[section].data.splice(index, 1);
      }
      updateSurveySections(dupSections);
    } else {
      let dupQuestions = [...questions];
      if (dupQuestions.length > 1) {
        dupQuestions.splice(index, 1);
      }
      updateSurveyQuestions(dupQuestions);
    }
  };

  enterChoice = (e, index, section, question) => {
    const {sections, questions, updateSurveySections, updateSurveyQuestions} = this.props;
    if (section !== null) {
      let dupSections = [...sections];
      let currentChoice = dupSections[section].data[question].options[index];
      if (currentChoice.length < e.target.value.length) {
        if (e.target.value.trim() !== '') {
          dupSections[section].data[question].options[index] = e.target.value;
        }
      }
      else {
        dupSections[section].data[question].options[index] = e.target.value;
      }
      updateSurveySections(dupSections);
    } else {
      let dupQuestions = [...questions];
      let currentChoice = dupQuestions[question].options[index];
      if (currentChoice.length < e.target.value.length) {
        if (e.target.value.trim() !== '') {
          dupQuestions[question].options[index] = e.target.value;
        }
      }
      else {
        dupQuestions[question].options[index] = e.target.value;
      }
      updateSurveyQuestions(dupQuestions);
    }
  };

  showChoice = (choices, section, question, questionType) => (
    choices.map((option, index) => (
      <Flex key={index} noWidth="100%" noCenter={true}>
        <Square >{this.optionChoice(index)}</Square>
        <ChoiceContainer>
          <StyledInput
            value={option}
            placeholder="Enter Option"
            onChange={(e) => this.enterChoice(e, index, section, question) }
            readOnly={questionType !== 2 ? true : false}
          />
        </ChoiceContainer>
        { questionType !== 2 ? null : choices.length > 1 &&
              <OptionCircle marginTop="16px" marginLeft="20px">
                <div className="circle" onClick={() => this.deleteChoice(section, question, index)}>
                  <div style={{width:"16px",height:"16px", display:"flex",alignItems:"center"}}>{closeIcon("white")}</div>
                  {/* <span className="glyphicon glyphicon-remove" /> */}
                </div>
              </OptionCircle>
        }
      </Flex>
    ))
  );

  showQuestion = (questions, section = null) => (
    questions.map((option, index) => (
      <Layout backgroundColor={section !== null ? "white" : "#e4e4e4"} key={section + '-' + index} marginBottom={"0"} minHeight={"auto"}>
        <Flex marginBottom="15px" fontSize="16px" marginTop="15px" noCenter={1}>
          <div className="header">
            {'Question ' + (index + 1)}
          </div>
          { questions.length > 1 &&
                  <OptionCircle marginTop="0" marginLeft="20px">
                    <div className="circle" onClick={() => this.deleteQuestion(section, index)}>
                      <div style={{width:"16px",height:"16px", display:"flex",alignItems:"center"}}>{closeIcon("white")}</div>
                      {/* <span className="glyphicon glyphicon-remove" /> */}
                    </div>
                  </OptionCircle>
          }
        </Flex>
        <InputFieldContainer survey={1} >
          <CustomTextAreaField
            placeholder="Enter Quiz Question"
            rows="3"
            name="description"
            data-gramm_editor="false"
            onChange={(e) => this.enterQuestion(e, section, index)}
            value={option.question}
          />
        </InputFieldContainer>
        <ActivityTypeContainer>
          <FieldTitle marginTop={'15px'} color={'#159fc9'}>Survey Option Types</FieldTitle>
          <ActivityDropdown
            title={OptionTypes[option.question_type - 1] || "Select an Options type"}
            id="dropdown-basic"
          >{
              this.surveyOptionTypes.map((item, typeindex) => (
                <CustomMenuItem
                  eventKey={typeindex}
                  key={item.questionType}
                  onSelect={() => this.props.onSelectSurveyType(index, item.questionType, item.options, section)}
                  active={option.question_type === item.questionType}
                >
                  {item.key}
                </CustomMenuItem>
              ))
            }
          </ActivityDropdown>
        </ActivityTypeContainer>
        {option.question_type > 0 && option.question_type === 4 ? null :
          <QuizContainer>
            <Flex marginTop="15px" fontSize="16px" noCenter={1}>
              <div className="header">
                            Options
              </div>
            </Flex>
          </QuizContainer>
        }
        <div>
          { option.question_type > 0 && this.showChoice(option.options, section, index, option.question_type) }
        </div>
        {option.question_type > 0 &&  option.question_type !== 2 ? null : option.options.length < 10 &&
              <Flex noCenter={1}>
                <OptionCircle marginTop="16px" bgColor="#93bf3d" onClick={() => this.addChoice(section, index)}>
                  <div className="circle">
                    {/* <span className="glyphicon glyphicon-plus"/> */}
                    <NewPlusIcon fill={"white"}/>
                  </div>
                </OptionCircle>
                <div className="addMore" onClick={() => this.addChoice(section, index)}> Add more option</div>
              </Flex>
        }
      </Layout>
    ))
  );

  showSection = (sections) => (
    sections.map((option, index) => (
      <Layout key={index} backgroundColor={"#e4e4e4"} marginBottom={"0"} noWidth="100%" minHeight={"auto"} noCenter={true}>
        <SectionContainer>
          <ChoiceContainer>
            <Flex marginBottom="5px" fontSize="16px" marginTop="15px" noCenter={1}>
              <div className="header">
                {'Section ' + (index + 1)}
              </div>
            </Flex>
            <StyledInput
              value={option.title}
              placeholder="Enter Section"
              onChange={(e) => this.enterSection(e, index) }
            />
          </ChoiceContainer>
          { sections.length > 1 &&
                  <OptionCircle marginTop="16px" marginLeft="20px">
                    <div className="circle" onClick={() => this.deleteSection(index)}>
                      <div style={{width:"16px",height:"16px", display:"flex",alignItems:"center"}}>{closeIcon("white")}</div>
                      {/* <span className="glyphicon glyphicon-remove" /> */}
                    </div>
                  </OptionCircle>
          }
          { this.showQuestion(option.data, index) }
          <Flex noCenter={1}>
            <OptionCircle marginTop="16px" bgColor="#93bf3d" onClick={() => this.addQuestions(index)}>
              <div className="circle">
                <NewPlusIcon fill={"white"}/>
              </div>
            </OptionCircle>
            <div className="addMore" onClick={() => this.addQuestions(index)}> Add more Question</div>
          </Flex>
        </SectionContainer>
      </Layout>

    ))
  );

  enterSection = (e, index) => {
    const {sections, updateSurveySections} = this.props;
    let dupChoice = [...sections];
    if(dupChoice[index].title.length <  e.target.value.length) {
      if(e.target.value.trim() !== '') {
        dupChoice[index].title = e.target.value;
      }
    }
    else {
      dupChoice[index].title = e.target.value;
    }
    updateSurveySections(dupChoice);
  };

  addSections = () => {
    const {sections, updateSurveySections} = this.props;
    let dupSections = [...sections];
    let obj =  {
      "title": '',
      "data": [
        {
          "question" : '',
          "options": this.surveyOptionTypes[0].options,
          "question_type": this.surveyOptionTypes[0].questionType
        }
      ]
    };
    dupSections.push(obj);
    updateSurveySections(dupSections);
  };

  addQuestions = (section, typeIndex = 0) => {
    const {sections, questions, updateSurveyQuestions, updateSurveySections, history} = this.props;
    if (section !== null) {
      let dupSections = [...sections];
      let obj = {
        "question": '',
        "options": this.surveyOptionTypes[typeIndex].options,
        "question_type": history.surveyOptionTypes[typeIndex].questionType
      };
      dupSections[section].data.push(obj);
      updateSurveySections(dupSections);
    } else {
      let dupQuestions = [...questions];
      let obj = {
        "question": '',
        "options": this.surveyOptionTypes[typeIndex].options,
        "question_type": this.surveyOptionTypes[typeIndex].questionType
      };
      dupQuestions.push(obj);
      updateSurveyQuestions(dupQuestions);
    }
  };

  toggleRadioButton = (e) => {
    let {prevQuestionsData, prevSectionsData, sections, questions, toggleSurveyRadioButton} = this.props;
    let obj = {};
    if (e.target.value === 0) {
      obj = {
        questions: [...prevQuestionsData],
        prevSectionsData: [...sections],
        sections: []
      };
    } else {
      obj = {
        sections: [...prevSectionsData],
        prevQuestionsData: [...questions],
        questions: []
      };
    }
    obj['hasSections'] = e.target.value;
    toggleSurveyRadioButton(obj);
  };

  render() {
    const {questions, sections, hasSections} = this.props;
    return (
      <TitleContainer>
        <FieldTitle>Has Sections:</FieldTitle>
        <RadioButtonSurveyConatiner>
          <RadioButton id="1" handler={this.toggleRadioButton} value={1} isChecked={hasSections == 1} label={'Yes'} challengeLeaderBoard={true}/>
          <RadioButton id="2" handler={this.toggleRadioButton} value={0} isChecked={hasSections == 0} label={'No'} challengeLeaderBoard={true}/>
        </RadioButtonSurveyConatiner>
        <TimeContainer>
          {hasSections == 0 ?
            <div>
              {this.showQuestion(questions)}
              <Flex noCenter={1}>
                <OptionCircle marginTop="16px" bgColor="#93bf3d" onClick={() => this.addQuestions(null)}>
                  <div className="circle">
                    {/* <span className="glyphicon glyphicon-plus"/> */}
                    <NewPlusIcon fill={"white"}/>
                  </div>
                </OptionCircle>
                <div className="addMore" onClick={() => this.addQuestions(null)}> Add more Question</div>
              </Flex>
            </div>
            :
            <div>
              { this.showSection(sections) }
              <Flex noCenter={1}>
                <OptionCircle marginTop="16px" bgColor="#93bf3d" onClick={() => this.addSections()}>
                  <div className="circle">
                    {/* <span className="glyphicon glyphicon-plus"/> */}
                    <NewPlusIcon fill={"white"}/>
                  </div>
                </OptionCircle>
                <div className="addMore" onClick={() => this.addSections()}> Add more section</div>
              </Flex>
            </div>
          }
        </TimeContainer>
      </TitleContainer>
    );
  }
}

SurveyQuestions.propTypes = {
  history: PropTypes.object,
  hasSections: PropTypes.number,
  sections: PropTypes.array,
  questions: PropTypes.array,
  prevQuestionsData: PropTypes.array,
  prevSectionsData: PropTypes.array,
  toggleSurveyRadioButton: PropTypes.func,
  updateSurveyQuestions: PropTypes.func,
  updateSurveySections: PropTypes.func,
  onSelectSurveyType: PropTypes.func
};

export default SurveyQuestions;
