import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

const BoldFont = 'Rubik-Bold';

const CustomModal = styled(Modal)`
  .modal-content {
    float: left;
    margin-bottom: 20px;
  }
`;

const CustomHeader = styled(Modal.Header)`
  padding: 20px 30px;
  text-transform: uppercase;
  
  .modal-title {
    font-size: 18px;
    letter-spacing: 0px;
    color: rgb(51,51,51);
    font-family: ${BoldFont};
  }
`;

const CustomBody = styled(Modal.Body)`
  background-color: white;
  float: left;
  padding: 0;
  margin-bottom: 5px;
`;

export { CustomHeader, CustomBody, CustomModal };
