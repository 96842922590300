import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from "prop-types";

import CreateChallengeForm from "../CreateChallengeForm";
import { CustomBody, CustomHeader, CustomModal } from "./styles";
import isEmpty from 'lodash/isEmpty';
import Waiting from "../Waiting";
import { withTranslation } from 'react-i18next';

class SurvivorChallenge extends Component {
  render() {
    const {showModal, onHide, survivorChallengeData, createChallenge, role, editLibrary, t } = this.props;
    let MandatoryFields = [];
    MandatoryFields = survivorChallengeData.isSurvivorChallenge ? ['title', 'imgSrc', 'activityType', 'description', 'challengePointValue', 'challengeMode',
      'weeklyIncrement', 'survivorAverage', 'companies'] : ['title', 'imgSrc', 'activityType', 'description', 'challengePointValue', 'companies'];
    const isSurvivorChallenge = survivorChallengeData['challenge_type'] === 'survivor';
    const isGroupChallenge = survivorChallengeData['challenge_type'] === 'new_group';
    if(showModal && isEmpty(survivorChallengeData)){
      return <Waiting />;
    }
    let headerText = editLibrary ? t('Edit') : t('Create');
    return (
      <CustomModal bsSize="large" animation={true} show={showModal} onHide={onHide}>
        <CustomHeader closeButton>
          <Modal.Title>
            {isSurvivorChallenge ? `${headerText} ${t("a survivor challenge")}` : isGroupChallenge ? `${t(headerText)} ${t("a group challenge")}` : `${t(headerText)} ${t("a classic challenge")}`}
          </Modal.Title>
        </CustomHeader>
        <CustomBody>
          <CreateChallengeForm
            isSurvivorChallenge={isSurvivorChallenge}
            isGroupChallenge = {isGroupChallenge}
            history={this.props.history}
            onHide={onHide}
            MandatoryFields={MandatoryFields}
            survivorChallengeData={survivorChallengeData}
            createChallenge={createChallenge}
            role={role}
            activityChallengeById={!editLibrary}
            editLibrary={editLibrary}
          />
        </CustomBody>
      </CustomModal>
    )
  }
}

SurvivorChallenge.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  survivorChallengeData: PropTypes.object,
  createChallenge: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  editLibrary: PropTypes.bool,
  t: PropTypes.func
};

export default (withTranslation()(SurvivorChallenge));