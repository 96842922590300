/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CreateEventButton } from '../MainEvents/styles';

import { Wrapper, SectionWrapper, ChallengesWrapper, ChallengeContainer, TitleContainer, ContentContainer, Button, PencilIcon} from '../ChallengesCreation/styles';

import Waiting from '../Waiting';

import { getSurveyLibraries, getSurveyDetails } from '../../redux/actions';
import {ImageUrl} from "../../utils/constants";
import {NoData} from '../ChallengeDashboardV2/styles' ;
import { getPermissionStatus } from '../../utils/methods';
import LazyImage from '../common/LazyImage/LazyImage';
class SurveyCreation extends Component {
  constructor() {
    super();
  }

  componentDidMount(){
    const{getSurveyLibraries} = this.props;
    getSurveyLibraries();
  }

  showCreateSurvey = () => {
    const { history } = this.props;
    history.push('/company/surveys/create');
  };

  renderSection = (information) => (
    <SectionWrapper>
      <span>{information}</span>
      <i className="fas fa-info-circle information-icon" />
    </SectionWrapper>
  );

  launchSurvey = (surveyId) => {
    const {getSurveyDetails, history} = this.props;
    getSurveyDetails(surveyId, history, '/company/surveys/launch');
  };

  launchCustomSurvey = () => {
    const {history} = this.props;
    history.push('/company/surveys/launch-custom');
  };

  editSurvey = (surveyId) => {
    const {getSurveyDetails, history} = this.props;
    getSurveyDetails(surveyId, history, '/company/surveys/edit');
  };

  renderSurveys = (surveys) => surveys.map((survey, index) => (
    <ChallengeContainer key={index}>
      <LazyImage div={"survey"} src={`${ImageUrl}/${survey.image}`} alt={survey.survey_name} onClick={() => this.launchSurvey(survey.id)}/>
      {getPermissionStatus("Update survey library", this.props.userPermissions) && <PencilIcon removeRight={true} className="fas fa-pencil-alt" onClick={() => this.editSurvey(survey.id, 1)}/>}
      <ContentContainer>
        <div>
          <span>{survey.survey_name}</span>
        </div>
        <div className="count">
          <span>{`Total Questions - ${survey['questions_count']}`}</span>
        </div>
        <div>
          <Button onClick={() => this.launchSurvey(survey.id)}>launch this survey</Button>
        </div>
      </ContentContainer>
    </ChallengeContainer>
  ));
  activityContainer = (heading, data, index) => (
    <div key={index}>
      {this.renderSection(heading)}
      {
        <ChallengesWrapper flex={1} margin={1}>
          {this.renderSurveys(data)}
        </ChallengesWrapper>
      }
    </div>
  );

  render(){
    const { isLoading, surveys, userPermissions} = this.props;
    if(!surveys || isLoading) {
      return <Waiting />
    }
    const launchCustomSurvey = getPermissionStatus("Create custom survey", userPermissions);
    const CreateLibrary = getPermissionStatus("Create survey library", userPermissions);
    return (
      <Wrapper>
        <TitleContainer>
          {launchCustomSurvey && <CreateEventButton bgColor={1} isBigButton onClick={this.launchCustomSurvey}>Launch Custom Survey</CreateEventButton>}
          { CreateLibrary &&
              <CreateEventButton bgColor={1} isBigButton addRightMargin={"20px"} onClick={this.showCreateSurvey}>Create survey library</CreateEventButton>
          }
        </TitleContainer>
        {surveys && surveys.length ?
          <div key={`Surveys`}>
            {this.activityContainer('Surveys', surveys, 1)}
          </div> : <NoData>No Survey libraries found</NoData>
        }
      </Wrapper>
    )
  }
}

SurveyCreation.propTypes = {
  history: PropTypes.object.isRequired,
  getSurveyLibraries: PropTypes.func.isRequired,
  getSurveyDetails: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  surveys: PropTypes.array,
  companyInfo: PropTypes.object,
  userPermissions: PropTypes.array
};

const mapStateToProps = (state) => ({
  isLoading: state.surveys.isLoading,
  surveys: state.surveys.surveyLibraries,
  userPermissions: state.profileData.userPermissions
});

const mapDispatchToProps = (dispatch) => ({
  getSurveyLibraries: () => dispatch(getSurveyLibraries()),
  getSurveyDetails: (surveyId, history, route) => dispatch(getSurveyDetails(surveyId, history, route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyCreation);
